import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const Separator = makeShortcode("Separator");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Kooperierende Verbände`}</h1>
      <p>{`Liebe Kolleginnen und Kollegen,`}</p>
      <p>{`Nur eine starke HP-Gemeinschaft kann sich Gehör verschaffen bei der Vertretung unserer Interessen in der Öffentlichkeit (bei den Medien, Krankenkassen, beim Gesetzgeber, in diversen Kommissionen, Gesundheitsämter…)`}</p>
      <p>{`Daher suchen und pflegen wir Kooperationen mit anderen HP-Verbänden.`}</p>
      <p>{`Neben unserer Mitgliedschaft im Dachverband Deutscher Heilpraktiker e.V. (DDH) halten wir engere Kontakte zum Freie Heilpraktiker e.V. (FH) mit Sitz in Düsseldorf
und dem Verband Deutscher Heilpraktiker e.V. (VDH) mit Sitz in Hannover.`}</p>
      <p>{`Durch gemeinsame Online-Vorträge und Präsenz-Aus- und Weiterbildungs-Seminare unserer Lehrbeauftragten ergeben sich zahlreiche Vorteile für die jeweiligen Verbands-Mitglieder.`}</p>
      <p>{`Wir wünschen Ihnen viele interessante und lehrreiche Veranstaltungen.`}</p>
      <Separator mdxType="Separator" />
      <p>{`Liebe Kolleginnen und Kollegen,`}</p>
      <p>{`nach ersten Kontakten und sehr fruchtbaren Vorgesprächen haben die Vorstände von FVDH und der Deutschen Gesellschaft für Therapeutische Hypnose und Hypnoseforschung e.V. (GTH) eine Kooperation vereinbart.
Ziel der Zusammenarbeit ist der Info-Austausch und die Förderung der Aus- und Weiterbildung.`}</p>
      <p>{`Interessant sind – neben den Hypnose-Ausbildungsseminaren – auch die Weiterbildungsangebote der GTH zur Integration der Hypnose in medizinischen Berufen zur Intensivierung anderer Therapieverfahren!`}</p>
      <p>{`Wir wünschen uns und den Mitgliedern beider Verbände eine fruchtbare und erfolgreiche Zusammenarbeit.`}</p>
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      